export const ROOT = "/";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const UI_COMPONENTS_PREFIX_PATH = "/ui-components";
export const PAGES_PREFIX_PATH = "/pages";
export const DOCS_PREFIX_PATH = "/docs";

export const APP_ROUTE = "/home";
export const CUSTOMER_ROUTE = "/customers";

export const ADMIN_USERS_MANAGEMENT = "/admin/users";
export const LIST_PERMISSION_MANAGEMENT = "/admin/list-permissions";
export const ADMIN_PERMISSIONS_MANAGEMENT = "/admin/permissions";
export const ADMIN_ROLE_DETAIL = "/admin/role/:id";
export const ADMIN_CONFIGURATION_MANAGEMENT = "/admin/configuration";

export const NOTIFICATION = "/notification";

//----------- QUẢN LÝ ĐƠN HÀNG -------------
export const ORDER_MANAGEMENT = "/order";
export const ORDER_HANOI_MANAGEMENT = "/order/hanoi";
export const PAYMENT_HIS_MANAGEMENT = "/order/payment-his";
export const ORDER_SHIP_NOT_SUCCESS_MANAGEMENT = "/order/not-sucess";
export const ORDER_COD_MANAGEMENT = "/order/cod";
export const RETURN_ORDER_MANAGEMENT = "/order/return";

//----------- QUẢN LÝ ĐƠN GỬI VIETTELPOST-------------
export const ORDER_MANAGEMENT_VIETTELPOST = "/order/viettelpost";
